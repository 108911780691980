<template>
  <app-modal
    :disabled="!selected"
    title="Select a teacher description version"
    :width="1100"
    @submit="onSubmit"
  >
    <v-item-group v-model="selected" class="grid gap-2">
      <v-item
        v-for="(item, index) in list"
        :key="index"
        v-slot="{ toggle, isSelected }"
        :value="item.teacher_overrides.description"
      >
        <v-card
          class="!border"
          :variant="isSelected ? 'outlined' : undefined"
          @click="toggle"
        >
          <v-card-text>
            <p class="mb-2 font-semibold">
              {{ itemTitle(item) }}
            </p>
            {{ item.teacher_overrides.description }}
          </v-card-text>
        </v-card>
      </v-item>
    </v-item-group>
  </app-modal>
</template>

<script lang="ts" setup>
import type { ProposalSearchableLineItemListView } from "~/business-areas/proposal/composables/proposal-searchable-line-item-list.hook";

const properties = defineProps<{
  teacher_id: DatabaseTable<"teachers">["id"];
  description: string;
}>();

const { data } = useLazyFetch("/api/proposals/searchable-line-items", {
  query: {
    teachers: properties.teacher_id,
  },
  default: () => [],
});

const list = computed(() => {
  return data.value
    ?.sort((a) => {
      if (!a.proposal) return -1;

      return 1;
    })
    .reduce((items, current) => {
      const alreadyFeaturesDescription = items
        .map((item) => item.teacher_overrides?.description)
        .filter(Boolean);

      if (
        current.teacher_overrides?.description &&
        !alreadyFeaturesDescription.includes(
          current.teacher_overrides?.description,
        )
      ) {
        items.push(current);
      }

      return items;
    }, [] as ProposalSearchableLineItemListView[]);
});

const selected = ref<string | undefined>();

whenever(
  () => list.value.length > 0,
  () => {
    selected.value =
      list.value.find(
        (item) => item.teacher_overrides.description === properties.description,
      )?.teacher_overrides.description ?? undefined;
  },
);

function onSubmit() {
  return useModal("proposalLineItemTeacherDescriptionListModal").close({
    confirmed: true,
    payload: selected.value,
  });
}

function itemTitle(item: ProposalSearchableLineItemListView) {
  if (item.proposal)
    return `From ${item.proposal.title} for ${item.proposal.customer_company_name}`;

  return `From origin teacher profile`;
}
</script>
